<template>
  <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.mobile" scrollable max-width="700px">
    <v-card>
      <v-card-title class="d-flex">
        <span>Entrega Nº {{ delivery.number }}</span>
        <span class="ml-md-auto">
          Agendada para {{ $format.dateBr(delivery.scheduled_to) }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h3>Informações da Venda</h3>
            <div>
              <b>Cliente:</b>
              <span v-if="hasCustomer">
                {{ delivery.sale.customer.code }} -
              </span>
              {{ customer_name }}
            </div>
            <template v-if="delivery.sale.salesman_id">
              <b>Vendedor:</b>
              {{ delivery.sale.salesman.name }}
              <div v-if="delivery.sale.salesman.whatsapp_contact">
                <b>Contato:</b>
                {{ delivery.sale.salesman.whatsapp_contact }}
              </div>
            </template>
            <div>
              <span>
                <b>Venda Nº</b>
                : {{ delivery.sale.number }}
              </span>
              <br />
              <b>
                Venda Realizada em
                {{ $format.dateBr(delivery.sale.created_at) }}
              </b>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h3>Logs da Entrega</h3>

            <div v-if="delivery.created_by">
              <b> Registrado: </b> {{ delivery.created_by.name }} em
              {{ $format.dateTimeBr(delivery.created_at) }}
            </div>
            <div v-if="delivery.processing_by">
              <b>Processo</b> : {{ delivery.processing_by.name }} em
              {{ $format.dateTimeBr(delivery.processing_at) }}
            </div>
            <div v-if="delivery.in_route_by">
              <b>Em Rota:</b>
              {{ delivery.in_route_by.name }} em
              {{ $format.dateTimeBr(delivery.in_route_at) }}
            </div>
            <div v-if="delivery.delivered_at">
              <b>Entregue:</b>
              {{ delivery.delivered_by.name }} em
              {{ $format.dateTimeBr(delivery.delivered_at) }}
            </div>
            <div v-if="delivery.canceled_at">
              <b>Cancelado:</b>
              {{ delivery.canceled_by.name }} em
              {{ $format.dateTimeBr(delivery.canceled_at) }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <b>Motorista</b>
            <div class="mt-2">
              <employee-select :disabled="!canUpdate" v-model="delivery.deliveryman" role-filter="motorista"
                label="Selecionar" />
            </div>
            <div class="mt-2" v-if="$acl.isLogistic() || $acl.isManager()">
              <v-btn color="primary" outlined @click="updateDeliveryman()">Atualizar</v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="hasCustomer">
          <v-col>
            <h3>Contatos</h3>
            <AppShowPhones :phones="delivery.sale.customer.phones" />
          </v-col>
        </v-row>

        <v-row v-if="delivery.main_address">
          <v-col cols="12">
            <h3>Endereço de Entrega</h3>
            {{ delivery.main_address.address }} ,
            {{ delivery.main_address.number }} ,
            {{ delivery.main_address.district }} ,
            {{ delivery.main_address.city }} ,
            {{ delivery.main_address.state }} ,
            {{ delivery.main_address.complement }} ,
            {{ $format.cep(delivery.main_address.postal_code) }} ,
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h3>Obervações</h3>
            {{ delivery.comment }}
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h3>Produtos Entregues</h3>
            <v-data-table :headers="headers" :items="delivery.delivery_products" 
              :items-per-page="-1" hide-default-footer mobile-breakpoint="0" disable-sort dense />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <!-- <v-btn
          v-if="delivery.status !== 'canceled'"
          color="error"
          text
          @click="cancelDelivery()"
        >
          Cancelar
        </v-btn> -->
        <app-cancel-btn v-if="delivery.status !== 'canceled' && $acl.can('deliveryCancel')" @click="cancelDelivery()" />
        <v-spacer />
        <template v-if="$acl.can('deliveryCreateEdit')">
          <v-btn v-if="isPending && $acl.can('updateToProcessing')" color="primary" outlined
            @click="updateToProcessing()">
            Iniciar Processo
          </v-btn>
          <v-btn v-if="isProcessing" color="primary" outlined @click="updateToInRoute()">
            Colocar em rota
          </v-btn>
          <v-btn v-if="isInRoute" color="primary" outlined @click="updateToDelivered()">
            Marcar como Entregue
          </v-btn>
        </template>
        <v-btn class="ml-10" text @click="dialog = false"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AppShowPhones from "../../app/sections/AppShowPhones.vue";
import EmployeeSelect from "../../employees/ui/EmployeeSelect.vue";
export default {
  components: { AppShowPhones, EmployeeSelect },
  data() {
    return {
      dialog: false,

      headers: [
        { text: "Descrição", value: "product.name" },
        { text: "Entregue", value: "quantity", align: "right", width: "15%" },
      ],
      deliveryId: null,
      delivery: {
        sale: {
          customer: {},
          salesman: {},
        },
        delivery_products: [],
        main_address: {},
      },
    };
  },

  created() {

    // this.open(5);
  },
  computed: {
    hasCustomer() {
      return this.delivery.sale.customer_id != null;
    },

    customer_name() {
      return this.hasCustomer
        ? this.delivery.sale.customer.name
        : "Consumidor final";
    },

    isPending() {
      return this.delivery.status == "pending";
    },
    isProcessing() {
      return this.delivery.status == "processing";
    },
    isInRoute() {
      return this.delivery.status == "in_route";
    },

    canUpdate() {
      if (this.$acl.isLogistic() || this.$acl.isManager()) return true;

      return this.delivery.status === 'processing' && this.delivery.employee_id == null;
    }
  },
  methods: {
    async open(delivery_id) {

      this.deliveryId = delivery_id;

      await this.loadDelivery();

      this.dialog = true;
    },


    async loadDelivery() {
      this.$loading.start();

      await this.$http
        .show("sale/delivery", this.deliveryId)
        .then((response) => {
          this.delivery = response.delivery;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    async updateToProcessing() {
      this.$loading.start();

      await this.updateStatus("processing");

      this.$loading.finish();

      this.$print.customPreview(
        `/entregas/entrega/imprimir/${this.delivery.id}`
      );
    },

    async updateToInRoute() {
      this.$loading.start();

      await this.updateStatus("in_route");

      this.$loading.finish();
    },

    async updateToDelivered() {
      this.$loading.start();

      await this.updateStatus("delivered");

      this.$loading.finish();
    },

    async updateStatus(status) {
      await this.$http
        .update("sale/delivery", this.delivery.id, {
          status: status,
          employee_id: this.delivery.deliveryman ? this.delivery.deliveryman.id : null,
        })
        .then((response) => {
          this.$emit("update");
          this.loadDelivery();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    async updateDeliveryman() {
      await this.$http
        .update("sale/delivery-update-deliveryman", this.delivery.id, {
          employee_id: this.delivery.deliveryman.id,
        })
        .then((response) => {
          this.$emit("update");
          this.loadDelivery();

        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    cancelDelivery() {
      this.$loading.start();
      this.$http
        .destroy("sale/delivery", this.delivery.id)
        .then((response) => {
          this.loadDelivery();
          this.$emit("update");

          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>
<style lang="sass" scoped>
button
  text-transform: capitalize
</style>
