<template>
  <div>
    <PendingStockTransferAlert />
    <v-row>
      <v-col cols="6">
        <app-text-search-field v-model="searchParams.text" @input="select()" />
      </v-col>

      <v-col v-if="$acl.can('deliveryCreateEdit')" class="text-right">
        <v-btn @click="handleUpdateDelivery()" color="primary">
          Atualizar entregar
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <company-select v-model="searchParams.company_id" clearable @input="select()"
          :filter="(company) => company.can_process_deliveries == 1" :item-value="(val) => val.id" />
      </v-col>
      <v-col>
        <app-select v-model="searchParams.type" :items="typeOptions" @input="select()" label="Tipo" />
      </v-col>
      <v-col>
        <CustomerSearch @input="setCustomer($event)" clearable />
      </v-col>
      <v-col>
        <ProductSearch :showStock="false" @input="setProduct($event)" clearable />
      </v-col>
      <v-col v-if="!$acl.isSalesman()">
        <SalesmanSearch @input="setSalesman($event)" clearable />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <app-number-field v-model="searchParams.sale_number" label="Nº Venda" :precision="0" @input="select()"
          :defaultValue="null" :forceZero="false" />
      </v-col>
      <v-col>
        <app-date-field v-model="searchParams.scheduled_to_start" @input="select()" label="Agendamento Inicial" />
      </v-col>
      <v-col>
        <app-date-field v-model="searchParams.scheduled_to_end" @input="select()" label="Agendamento Final" />
      </v-col>
      <v-col>
        <app-date-field v-model="searchParams.delivered_at_start" @input="select()" label="Entrega Inicial" />
      </v-col>
      <v-col>
        <app-date-field v-model="searchParams.delivered_at_end" @input="select()" label="Entrega Final" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <app-text-field label="Cidade" readonly :value="searchParams.city" @click="handleCitySearch()" clearable
          @click:clear="handleClearCity()" prepend-inner-icon="mdi-magnify" />
      </v-col>

      <v-col cols="5">
        <app-text-field label="Endereço" v-model="searchParams.address" v-debounce:300ms="select"
          debounce-events="input" />
      </v-col>
      <v-col>
        <employee-select @input="setEmployee($event)" clearable label="Motorista" role-filter="motorista" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-tabs>
          <v-tab class="text-capitalize" v-for="(status, index) in tabStatus" :key="index"
            @click="search(status.value)">
            {{ status.text }}
          </v-tab>
        </v-tabs>

        <v-data-table :headers="headers" :items="deliveries.data" :items-per-page="-1"
          hide-default-footer disable-sort @click:row="handleOpenViewDelivery($event.id)">
          <template v-slot:[`item.company`]="{ item }">
            <CompanyChip v-if="item.company" class="ml-2" :company="item.company" />
          </template>
          <template v-slot:[`item.number`]="{ item }">
            #{{ item.number }}
            <DeliveryTypeIcon :type="item.type" />
          </template>
          <template v-slot:[`item.sale`]="{ item }">
            #{{ item.sale.number }}
            <CustomerSpan :customer="item.sale.customer" />
            <br />
            <small v-if="item.type == 'delivery'">
              {{ item.main_address.city }}/{{ item.main_address.state }}
            </small>
          </template>
          <template v-slot:[`item.deliveryman`]="{ item }">
            {{ item.deliveryman ? item.deliveryman.short_name : "" }}
          </template>
          <template v-slot:[`item.scheduled_to`]="{ item }">
            {{ $format.dateBr(item.scheduled_to) }}
          </template>
          <template v-slot:[`item.updated`]="{ item }">
            {{ getUpdatedAtByStatus(item) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <DeliveryStatusChip small :status="item.status" />
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text icon color="primary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item v-if="canPrint(item)" @click="print(item.id)">
                  <v-list-item-icon>
                    <app-icon>print</app-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Imprimir Romaneio</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <app-pagination @click="select($event)" :data="deliveries" />
      </v-col>
    </v-row>
    <ViewDelivery @update="select()" ref="ViewDelivery" />
    <UpdateStatusDelivery @update="select()" ref="UpdateStatusDelivery" />
    <SearchCity @input="setCity($event)" ref="SearchCity" :item-value="(val) => val.city" />
  </div>
</template>

<script>
import CompanyChip from "@/components/company/sections/CompanyChip.vue";

import DeliveryTypeIcon from "@/components/delivery/section/DeliveryTypeIcon.vue";
import CustomerSpan from "@/components/customer/sections/CustomerSpan.vue";
import CustomerSearch from "@/components/customer/ui/CustomerSearch.vue";
import DeliveryStatusChip from "@/components/delivery/section/DeliveryStatusChip.vue";
import ViewDelivery from "@/components/delivery/section/ViewDelivery.vue";
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import UpdateStatusDelivery from "@/components/delivery/section/UpdateStatusDelivery.vue";
import SearchCity from "@/components/app/forms/SearchCity";
import PendingStockTransferAlert from "@/components/stock-transfer/sections/PendingStockTransferAlert.vue";
import SalesmanSearch from "@/components/employees/ui/SalesmanSearch.vue";
import ProductSearch from "@/components/product/ui/ProductSearch.vue";
import EmployeeSelect from "@/components/employees/ui/EmployeeSelect.vue";
export default {
  components: {
    ViewDelivery,
    DeliveryStatusChip,
    CustomerSpan,
    DeliveryTypeIcon,
    CompanyChip,
    CompanySelect,
    CustomerSearch,
    UpdateStatusDelivery,
    SearchCity,
    PendingStockTransferAlert,
    SalesmanSearch,
    ProductSearch,
    EmployeeSelect,
  },

  data() {
    return {
      searchParams: {
        company_id: null,
        status: "",
        sale_number: this.$route.query.sale_number,
        city: null,
        address: null,
      },
      deliveries: {},
      tabStatus: [
        { text: "Todos", value: "" },
        { text: "Pendente", value: "pending" },
        { text: "Processando", value: "processing" },
        { text: "Em Rota", value: "in_route" },
        { text: "Entregue", value: "delivered" },
        { text: "Canceladas", value: "canceled" },
      ],
      headers: [
        { text: "Empresa", value: "company", align: "center", width: "5%" },
        { text: "Entrega", value: "number", align: "center", width: "10%" }, // todo make it code
        { text: "Venda", value: "sale", align: "left" },
        { text: "Motorista", value: "deliveryman", align: "left" },
        { text: "Programado para", value: "scheduled_to", align: "center" },
        { text: "Ultima Atualização", value: "updated", align: "center" },
        { text: "Status", value: "status", align: "center", width: "5%" },
        { text: "", value: "action", align: "center", width: "5%" },
      ],
      typeOptions: [
        { text: "Todos", value: "" },
        { text: "Entrega", value: "delivery" },
        { text: "Retirada", value: "pickup" },
      ],
    };
  },

  computed: {
    user() {
      return this.$store.state.auth.userData.user;
    },
  },

  created() {
    if (this.$acl.isSalesman()) {
      this.setSalesman(this.user);
    }
  },

  methods: {
    select(page = 1) {
      this.searchParams.page = page;
      this.$loading.start();
      this.$http
        .index("sale/delivery", this.searchParams)
        .then((response) => {
          this.deliveries = response.deliveries;
          this.$loading.finish();
        })
        .catch((erro) => {
          this.$loading.finish();
        });
    },
    search(status) {
      this.searchParams.status = status;
      this.select();
    },

    handleOpenViewDelivery(delivery_id) {
      this.$refs.ViewDelivery.open(delivery_id);
    },

    print(delivery_id) {
      window.open(`/entregas/entrega/imprimir/${delivery_id}`);
    },
    setCustomer(customer) {
      this.searchParams.customer_id = customer ? customer.id : null;
      this.select();
    },
    handleUpdateDelivery() {
      this.$refs.UpdateStatusDelivery.open();
    },
    setEmployee(employee) {
      this.searchParams.employee_id = employee ? employee.id : null;
      this.select();
    },

    setCompanyId() {
      this.searchParams.company_id = this.user.company_id;
    },

    canPrint(delivery) {
      return (
        this.$acl.can("printDelivery") &&
        delivery.status !== "pending" &&
        delivery.status !== "canceled"
      );
    },
    getUpdatedAtByStatus(delivery) {
      var statuses = {
        pending: "created_at",
        processing: "processing_at",
        in_route: "in_route_at",
        delivered: "delivered_at",
        canceled: "canceled_at",
      };

      return this.$format.dateTimeBr(delivery[statuses[delivery.status]]);
    },
    setCity(city) {
      this.searchParams.city = city ? city : null;
      this.select();
    },
    handleCitySearch() {
      this.$refs.SearchCity.open();
    },
    handleClearCity() {
      this.searchParams.city = null;
      this.select();
    },
    setSalesman(salesman) {
      this.searchParams.salesman_id = salesman ? salesman.id : null;
      this.select();
    },



    setProduct(product) {
      this.searchParams.product_id = product ? product.id : null;
      this.select();
    },
  },
};
</script>

<style></style>